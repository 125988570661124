<template>
  <div class="monitor-index">
    <el-table class="list-table list-table-border" ref="multipleTable" :data="listData" tooltip-effect="dark"
      style="width: 100%" :stripe="true" border v-loading="tableLoading">
      <el-table-column label="设备名称" :show-overflow-tooltip="true" prop="DeviceName"></el-table-column>
      <el-table-column label="设备类型" prop="DeviceType"></el-table-column>
      <el-table-column label="设备位置" :show-overflow-tooltip="true" prop="Address"></el-table-column>
      <el-table-column label="状态" prop="IsOn">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.IsOn == 1" effect="dark" size="small">在线</el-tag>
          <el-tag v-if="scope.row.IsOn == 0" type="danger" effect="dark" size="small">离线</el-tag>
          <el-tag v-if="scope.row.IsOn == 2" type="success" effect="dark" size="small">维修</el-tag>
          <el-tag v-if="scope.row.IsOn == 3" type="info" effect="dark" size="small">拆除</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="最新时间" prop="LastUpdateTime">
        <template slot-scope="scope">
          {{ scope.row.LastUpdateTime | datetime }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <router-link :to="'/device/detail?id=' + scope.row.Id">
            <el-button type="success" icon="el-icon-view" size="mini">详情</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>

    <pagination background layout="prev, pager, next" prev-text="上一页" next-text="下一页" class="list-pagination"
      :page-size="page.pagesize" hide-on-single-page :total="page.total" :current-page.sync="page.pageindex"
      @current-change="onPageChange">
    </pagination>
  </div>
</template>
<script>
import { getDeviceList } from "@/api/device";
export default {
  name: "DeviceList",
  data() {
    return {
      listData: [],
      tableLoading: false,
      listfilter: {
        monitorid: "",
        DeviceType: "",
        state: "",
        fname: "",
        fstarttime: "",
        fendtime: "",
      },
      page: {
        pagesize: 10,
        pageindex: 1,
        total: 0,
      },
    };
  },
  props: {
    monitorid: [String, Number],
  },
  components: {},
  methods: {
    onPageChange(pageindex) {
      this.page.pageindex = pageindex;
      this.getListData();
    },
    getListData() {
      this.tableLoading = true;

      if (this.monitorid) {
        this.listfilter.monitorid = this.monitorid * 1;
      }
      if (this.$route.query.monitorid) {
        this.listfilter.monitorid = this.$route.query.monitorid * 1;
      }
      getDeviceList(
        this.page.pageindex,
        this.page.pagesize,
        this.listfilter
      ).then((resdata) => {
        this.tableLoading = false;
        if (resdata.code == 0) {
          this.listData = resdata.data.data;
          this.page.total = resdata.data.count * 1;
        } else {
          this.$message({
            type: "error",
            message: resdata.msg,
          });
        }
      });
    },
  },
  created() {
    this.getListData();
  },
  watch: {
    monitorid: function (newvalue) {
      this.page.pageindex = 1;
      this.listfilter.monitorid = this.monitorid * 1;
      this.getListData();
    },
  },
};
</script>