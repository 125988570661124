<template>
  <div>
    <el-tabs class="geology-tabs" v-model="activeTab">
      <el-tab-pane label="基本信息" name="tabBase">
        <el-form ref="form" class="geology-form" label-width="120px">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点:">
                {{ monitorDetail.monitorModel.DangerName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="设备:">
                {{ monitorDetail.monitorModel.DeviceName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位名称:">
                {{ monitorDetail.monitorModel.MonitorName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点位编号:">
                {{ monitorDetail.monitorModel.MonitorCode }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测部位:">
                {{ monitorDetail.monitorModel.MonitorPosition }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测方法:">
                {{ monitorDetail.monitorModel.MonitorMethod }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测级别:">
                {{ monitorDetail.monitorModel.MonitorLevel }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测内容:">
                {{ monitorDetail.monitorModel.MonitorContent }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="建设日期:">
                {{ monitorDetail.monitorModel.BuildTime }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="安装日期:">
                {{ monitorDetail.monitorModel.InstallTime }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="初测日期:">
                {{ monitorDetail.monitorModel.InitTime }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="承建单位:">
                {{ monitorDetail.monitorModel.ContractUnitName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="运维单位:">
                {{ monitorDetail.monitorModel.MaintainUnitName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="运维负责人:">
                {{ monitorDetail.monitorModel.MaintainUnitUserName }}
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="经纬度:">
                {{ monitorDetail.monitorModel.Longitude }},{{
                  monitorDetail.monitorModel.Latitude
                }}
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">备注信息</el-divider>
          <el-row>
            <el-col :span="19" :offset="1">
              <el-form-item label="备注:">
                {{ monitorDetail.monitorModel.Marks }}
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="图片信息" name="tabImage">
        <div style="padding: 20px 0">
          <el-image v-for="(item, index) in monitorDetail.images" :key="index"
            style="width: 100px; height: 100px; margin-right: 20px" :src="item.FilePath" fit="contain" :alt="item.Name"
            :preview-src-list="imagePreviewlList">
          </el-image>
        </div>
      </el-tab-pane>
      <el-tab-pane label="文件信息" name="tabFile">
        <el-table class="list-table" :data="monitorDetail.files" tooltip-effect="dark"
          style="width: 100%; margin-top: 20px" :stripe="true" :border="false">
          <el-table-column label="文件名称" prop="Name"></el-table-column>
          <el-table-column label="上传时间" prop="CreateTime">
            <template slot-scope="scope">
              {{ scope.row.CreateTime | datetime }}
            </template>
          </el-table-column>
          <el-table-column label="文件地址" prop="FilePath"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="监测设备" name="tabDevice">
        <monitor-device :monitorid="detailid"></monitor-device>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getMonitorDetail } from "@/api/base";
import monitorDevice from "./monitorDevice.vue"
export default {
  data() {
    return {
      activeTab: "tabBase",
      monitorDetail: {
        monitorModel: "",
        images: [],
        files: [],
      },
      imagePreviewlList: [],
    };
  },
  props: {
    detailid: [String, Number],
    visible: Boolean,
  },
  components: {
    monitorDevice,
  },
  methods: {
    getMonitorDetail() {
      getMonitorDetail(this.detailid)
        .then((res) => {
          if (res.code == 0) {
            this.monitorDetail = res.data;

            var imagePreviewlList = [];
            this.monitorDetail.images.forEach((item) => {
              imagePreviewlList.push(item.FilePath);
            });
            this.imagePreviewlList = imagePreviewlList;
          }
        })
        .catch((errmsg) => {
          this.$message.error(errmsg);
        });
    },
  },
  created() {
    this.getMonitorDetail();
  },
  watch: {
    detailid: function () {
      this.getMonitorDetail();
    },
    visible: function () {
      if (!this.visible) {
        this.activeTab = "tabBase";
      }
    },
  },
};
</script>